
html {
  scroll-behavior: smooth;
}



p {
  line-height: var(--global-line-height);
}


.mt-4 {
  font-size: 1.5rem;
}

.text-center {
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
}



header {
  margin:auto;
}


p {
  font-family:'Verdana',"sans-serif"
}

nav {
  font-size: 1.1rem;
}



.terminal-card {
  border: 1px solid var(--navbar-color);
  

}
.terminal-card>header {
background-color: var(--navbar-color);
}

:root {
  --global-line-height: 1.2;
  --letter-spacing: .1rem;
  
  --hover-color: transparent;
        --global-font-size: 15px;
        --global-line-height: 1.4em;
        --global-space: 10px;
        --font-stack: Menlo, Monaco, Lucida Console, Liberation Mono,
          DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
          serif;
        --mono-font-stack: Menlo, Monaco, Lucida Console, Liberation Mono,
          DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace,
          serif;
        --background-color:#2e2b55;
        --page-width: 60em;
        --font-color: #f7f7f8;
        --white-hover-color: #fdf6e3;
        --invert-font-color: #2e2b55;
        --secondary-color: #a5fe90;
        --tertiary-color: #a3abba;
        --primary-color: #f993fe;
        --error-color: #a5fe90;
        --navbar-color: #a599e9;
        --progress-bar-background: #3f3f44;
        --progress-bar-fill: #62c4ff;
        --code-bg-color: #3f3f44;
        --input-style: solid;
        --display-h1-decoration: none;
   
}
  
















@media screen  and (max-width: 2000px) {

  
}


@media screen  and (max-width: 1150px) {

}

@media screen  and (max-width: 770px) {

  
}
  
